// @flow
/* eslint-disable import/max-dependencies */
import React, {
  type StatelessFunctionalComponent, useEffect, useState,
} from "react";
import type { Dispatch } from "redux";
import { bindActionCreators } from "redux";
import {
  Box, Button, TextField,
} from "@mui/material";
import { Autocomplete } from "@mui/lab";
import { connect } from "react-redux";
import { makeStyles } from "@mui/styles";
import { getDictionarySaga } from "../../actions/dictionaries";
import type { GetDictionarySaga } from "../../actions/dictionaries";
import { getDictionary } from "../../selectors/dictionaries";
import type { Actions, State } from "../../store";
import type { DropDownObjItemType } from "../../reducers/dictionaries";
import { PERFORMER } from "../../store";

export type OwnProps = $ReadOnly<{|
  onNext: (string[]) => mixed,
|}>;

export type StateToProps = $ReadOnly<{|
  productCompaniesDropdown: DropDownObjItemType[],
|}>;

export type DispatchToProps = $ReadOnly<{|
  onGetProductCompaniesDropdownData: () => mixed,
|}>;

export type Props = $ReadOnly<{|
  ...OwnProps,
  ...StateToProps,
  ...DispatchToProps,
|}>;

const mapStateToProps: (state: State) => StateToProps = (state) => ({
  productCompaniesDropdown: getDictionary(state, "productCompany"),
});

const mapDispatchToProps: (Dispatch<Actions>) => DispatchToProps = (dispatch) => bindActionCreators({
  onGetProductCompaniesDropdownData: (): GetDictionarySaga => getDictionarySaga("productCompany", { performer: PERFORMER }),
}, dispatch);

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiInputBase-root": {
      border: "none",
      borderRadius: "0",
      background: theme.palette.background.light,
      padding: "0 65px 0 10px!important",
      "& input": {
        "WebkitTextFillColor": "#fff",
        padding: "0!important",
      },
      "& .MuiSvgIcon-root": {
        color: "#fff",
      },
      "&:hover": {
        background: theme.palette.background.default,
      },
      "&.Mui-focused": {
        background: "#fff",
        color: theme.palette.text.main,
        "& input": {
          "WebkitTextFillColor": theme.palette.text.main,
          "&[value=\"\"]": {
            "WebkitTextFillColor": "rgba(0, 0, 0, 0.25)",
          },
        },
        "& .MuiSvgIcon-root": {
          color: "rgba(0, 0, 0, 0.25)",
        },
      },
      "& .MuiOutlinedInput-notchedOutline": {
        display: "none",
      },
    },
  },
  paper: {
    borderRadius: "0!important",
  },
}));

const SelectProductCompanyField: StatelessFunctionalComponent<Props> = ({
  onNext,
  productCompaniesDropdown,
  onGetProductCompaniesDropdownData,
}: Props) => {
  const classes = useStyles();
  const [company, setCompany] = useState(null);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    onGetProductCompaniesDropdownData();
  }, []);

  useEffect(() => {
    const list = productCompaniesDropdown.map(({ label }) => ({ label, value: label }));
    setOptions([{ label: "All", value: list.map(({ label }) => label) }, ...list]);
  }, [productCompaniesDropdown]);

  const handleSubmit = () => {
    if (company) {
      onNext(Array.isArray(company.value) ? company.value : [company.value]);
    }
  };

  return (
    <>
      <Autocomplete
        id="combo-box"
        options={options}
        value={company}
        onChange={(e, value) => setCompany(value)}
        getOptionLabel={(option) => String(option.label)}
        isOptionEqualToValue={(o, v) => o.value === v.value}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            fullWidth
            size="small"
            placeholder="Choose from the list"
          />
        )}
        required
        fullWidth
        size="small"
        name="company"
        classes={classes}
      />
      <Button
        fullWidth
        variant="primary"
        name="loginButton"
        onClick={handleSubmit}
        disabled={!company}
        sx={{
          width: "160px", margin: "40px auto 0",
        }}
      >
        <Box width="100%">Log In</Box>
      </Button>
    </>
  );
};

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(SelectProductCompanyField);
