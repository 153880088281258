// @flow
import * as React from "react";
import { Box, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";

import type { DateRangeFooterProps } from "@fas/cpa-cabinet-ui/lib/DateRangePicker/DateRangePicker.types";

const styles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "20px",
    marginTop: "20px",
  },
}));

const DateRangeFooter = ({
  onClose,
  onApply,
  classes = {},
}: DateRangeFooterProps) => {
  const ownClasses = styles();
  return (
    <Box className={clsx(ownClasses.root, classes.root)}>
      <Button variant="primary" onClick={onClose} className={clsx(ownClasses.cancel, classes.cancel)}>
        Cancel
      </Button>
      <Button variant="main" onClick={onApply} className={clsx(ownClasses.apply, classes.apply)}>
        Apply
      </Button>
    </Box>
  );
};

export default DateRangeFooter;
