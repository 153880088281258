// @flow
import React from "react";
import ReactDOM from "react-dom";
import "@fas/react-scripts";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import configureStore from "./store";
import App from "./components/App";
import "./index.css";

function prepare(): Promise<void> {
  if (process.env.NODE_ENV !== "production" && process.env.REACT_APP_DEV_SW) {
    // eslint-disable-next-line global-require
    const { mockApi } = require("./mocks");// we have to use require instead of import because we don't want to add mocks to the build
    return mockApi.makeServer();
  }
  return Promise.resolve();
}

prepare().then(() => {
  ReactDOM.render(
    <Provider store={configureStore()}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>,
    // $FlowFixMe
    document.getElementById("root")
  );
});
