// @flow
import React, {
  type Node,
} from "react";
import {
  TableCell,
  TableRow,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import type {
  Column,
  RowProps,
} from "./types/TableComponent.types";

function Row <T: Object>({
  columns,
  row,
  classes = {},
}: RowProps<T>): Node {
  return (
    <>
      <TableRow
        hover
        aria-checked={false}
        tabIndex={-1}
        data-testid="table-component-table-row"
        className={classes.row}
      >
        {columns.map((column: Column<T>): Node => (
          <TableCell
            size="small"
            key={column.key}
            className={clsx(classes.cell, column.className)}
            colSpan={column.colSpan || 1}
          >
            {column.render ? column.render({ row, column }) : String(row[column.field])}
          </TableCell>
        ))}
      </TableRow>
    </>
  );
}

const useStylesTotal = makeStyles((theme) => ({
  row: {
    outline: `1px solid ${theme.palette.text.primary}`,
    background: "rgba(180, 90, 212, 0.10)",
  },
  cell: {
    borderColor: theme.palette.text.primary,
    color: theme.palette.text.primary,
  },
}));

export const TotalRow = <T: Object>(props: RowProps<T>) => <Row {...props} classes={useStylesTotal()} />;

export default Row;
