// @flow
import React, { type StatelessFunctionalComponent } from "react";
import {
  Container, Box, Typography, List, ListItem, type Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

type Classes = {
  root: string,
}

const useStyles: () => Classes = makeStyles((theme: Theme): {[key: $Keys<Classes>]: mixed} => ({
  root: {
    marginBottom: "48px",
    "& h2": {
      marginTop: "18px!important",
    },
    "& .MuiTypography-h5": {
      margin: "40px 0 10px",
    },
    "& a": {
      color: theme.palette.text.primary,
    },
    "& p": {
      margin: "10px 0",
    },
    "& .MuiList-root": {
      listStyleType: "disc",
      paddingLeft: "16px",
      "& li": {
        display: "list-item",
      },
    },
  },
}));

const Privacy: StatelessFunctionalComponent<{}> = () => {
  const classes: Classes = useStyles();

  return (
    <Container>
      <Box className={classes.root}>
        <Typography component="h2" variant="h5">PRIVACY POLICY</Typography>
        <Typography component="p">
          At Maverex Services Kft, located at 2161 Csomád, Leventeutca 14/a, Hungary (“Maverex” or “We”),
          we prioritize the protection and respect of your privacy.
          This Privacy Policy is an integral component of our Terms of Use,
          available at our web-site (&ldquo;Site&ldquo;):&nbsp;
          <a href="//maverexplatform.com">www.maverexplatform.com</a>
          .
          It outlines the guidelines governing the collection, storage,
          and processing of your personal data provided to us.
        </Typography>
        <Typography component="p">Your consent is essential for us to process your data, which is obtained when you sign up for our services. However, you retain the right to revoke this consent at any time. Your use of our services constitutes acceptance of this Privacy Policy. If you disagree with any provision outlined below, please discontinue the use of our services immediately.</Typography>
        <Typography component="p">For minors (under 18), consent must be obtained from a parent or guardian. Any changes to this policy will be communicated to the user and their guardian, and continued use of the service after the notice will be deemed acceptance of the updated terms.</Typography>
        <Typography component="p">This Privacy Policy encompasses all aspects of your data and our actions concerning it. If any provision is unclear, we recommend seeking legal counsel (at your own expense).</Typography>

        <Typography component="h6" variant="h5">Information Collection</Typography>
        <Typography component="p">
          <u>If you are a business entity</u>
          , we collect and process the following information about you:
        </Typography>
        <List>
          <ListItem>
            business entity name (title),
          </ListItem>
          <ListItem>
            company registration code,
          </ListItem>
          <ListItem>
            tax/VAT code,
          </ListItem>
          <ListItem>
            business entity related e-mail address,
          </ListItem>
          <ListItem>
            mailing address,
          </ListItem>
          <ListItem>
            daytime and/or cellular telephone numbers, fax number,
          </ListItem>
          <ListItem>
            account information
          </ListItem>
          <ListItem>
            information about authorized persons of your entity
          </ListItem>
          <ListItem>
            bank details or any other information that we require
            in order to pay any amounts due to you via your Account
          </ListItem>
          <ListItem>
            any other information requested on the applicable registration form or by law (in particular cases)
          </ListItem>
        </List>

        <Typography component="p">
          <u>If you are a private person</u>
          ,
          we collect and process your personally identifiable information when you register for an Account
          or otherwise choose to provide personally identifiable information to us.
          Personally identifiable information is any information that can be used to identify you.
          This may include, but is not limited to:
        </Typography>
        <List>
          <ListItem>
            first and last name
          </ListItem>
          <ListItem>
            age and passport data
          </ListItem>
          <ListItem>
            country of residence and your location
          </ListItem>
          <ListItem>
            daytime and/or cellular telephone numbers
          </ListItem>
          <ListItem>
            personal e-mail address, mailing address
          </ListItem>
          <ListItem>
            information that we require in order to pay any amounts due to you via your Account
          </ListItem>
          <ListItem>
            any other information requested on the applicable registration form or by law (in particular cases)
          </ListItem>
        </List>

        <Typography component="p">
          For both business entities and private persons, we may collect also non-personally
          identifiable information about you when you visit certain pages of
          this Site and/or register for an Account, such as:
        </Typography>
        <List>
          <ListItem>
            type of browser you are using
          </ListItem>
          <ListItem>
            type of operating system you are using and the domain name of your Internet service provider
          </ListItem>
          <ListItem>
            Your computer or mobile device&apos;s location (if enabled), which remains anonymous
          </ListItem>
          <ListItem>
            Correspondence records if you contact us
          </ListItem>
          <ListItem>
            Website usage data collected through cookies.
          </ListItem>
        </List>

        <Typography component="p">We use the non-personally identifiable information that we collect to improve the design and content of the Site and to enable us to personalize your Internet experience. We also may use this information in the aggregate to analyze Site usage.</Typography>

        <Typography component="p">
          You have the liberty to adjust your data settings or contact us at&nbsp;
          <a href="mailto:info@maverexserv.com">info@maverexserv.com</a>
          &nbsp;to modify your preferences. However, note that opting out of certain data
          processing/storage may affect site functionality and user experience.
        </Typography>

        <Typography component="h6" variant="h5">Information Usage</Typography>
        <Typography component="p">We use your information for:</Typography>
        <List>
          <ListItem>
            Sending updates or announcements relevant to our business and notifying you about service changes.
          </ListItem>
          <ListItem>
            Enhancing the Site&apos;s structure and usability.
          </ListItem>
          <ListItem>
            Personalizing your site experience.
          </ListItem>
          <ListItem>
            Addressing inquiries and comments.
          </ListItem>
          <ListItem>
            Conducting market analysis and research.
          </ListItem>
          <ListItem>
            Complying with legal obligations.
          </ListItem>
          <ListItem>
            Provision of services and products.
          </ListItem>
          <ListItem>
            We also communicate with you to confirm changes, enforce rights, and offer additional
            services/products.Additionally, we may offer push notifications, with the option to accept or decline.
          </ListItem>
        </List>
        <Typography component="p">Furthermore, aggregated non-personal information is used for service enhancement, performance analysis, and offering products/services from our partners.</Typography>

        <Typography component="h6" variant="h5">Data Disclosure</Typography>
        <Typography component="p">As a general rule, and other than in connection with the limited exceptions set forth below, we will not sell, share or rent your personally identifiable information to or with others. Notwithstanding the foregoing, we may, from time to time, provide such information to certain third-party administrative vendors for efficiency purposes in providing administrative or program management services in connection with your Account. Any third-party vendor so used has agreed to protect the confidentiality of information provided by us.</Typography>
        <Typography component="p">Further, we reserve the right to share your personally identifiable information:</Typography>
        <Typography component="p">
          (a) where required by law, or requested by a court of competent jurisdiction;
          <br />
          (b) in the event of any reorganization, merger, sale, joint venture, assignment,
          transfer or other disposition of all or any portion of our business, assets or stock;
          <br />
          (c) where you are in violation of this Privacy Policy, and/or the Terms and Conditions;
          <br />
          (d) in the case of a dispute; or
          <br />
          (e) where we determine, at our sole discretion, that such disclosure is necessary
          to protect our rights and/or a third party, or necessary to protect us from liability of any kind.
        </Typography>

        <Typography component="p">The aforementioned includes exchanging information with other companies and organizations for purposes of fraud protection.</Typography>
        <Typography component="p">Your information may be shared with:</Typography>
        <List>
          <ListItem>
            Other companies within our corporate group
          </ListItem>
          <ListItem>
            Prospective buyers or our professional advisers
          </ListItem>
          <ListItem>
            Third-party service providers assisting us
          </ListItem>
          <ListItem>
            Other users of the Site, as needed
          </ListItem>
          <ListItem>
            Government and administrative bodies (in case of your violation of this Privacy Policy,
            and/or the Terms and Conditions, if required by law).
          </ListItem>
        </List>
        <Typography component="p">
          Your personal data may also be shared with partner businesses, subject to your agreement.
          Legal obligations may necessitate data disclosure, including court orders,
          governmental acts, or national security issues.
        </Typography>

        <Typography component="h6" variant="h5">European Right to Erasure</Typography>
        <Typography component="p">European users can request data deletion upon discontinuing our services. Contact Support for erasure requests.</Typography>

        <Typography component="h6" variant="h5">Cookies</Typography>
        <Typography component="p">We collect statistical browsing data through cookies for site improvement. You can adjust your browser settings to decline cookies, although this may impact site functionality.</Typography>

        <Typography component="h6" variant="h5">Data Storage and Retention </Typography>
        <Typography component="p">Your data may be stored and processed outside the European Economic Area. By submitting your data, you consent to this processing.</Typography>
        <Typography component="p">We retain personal data as long as necessary for the purposes outlined in this Privacy Policy.</Typography>
        <Typography component="p">
          Data is retained based on account activity:
        </Typography>
        <List>
          <ListItem>
            Active accounts: until account deletion
          </ListItem>
          <ListItem>
            Inactive accounts: stored until deletion, with advertisements delivered
          </ListItem>
          <ListItem>
            Deleted accounts: in compliance with domestic and international data protections legislation,
            we can remove all data associated with a user account upon a user’s request
          </ListItem>
          <ListItem>
            Banned accounts: email and account information stored indefinitely.
          </ListItem>
        </List>

        <Typography component="h6" variant="h5">Deleting and Updating Your Information</Typography>
        <Typography component="p">
          If you would like to delete or update the personally identifiable information that we have  collected
          from you, simply email us at&nbsp;
          <a href="mailto:info@maverexserv.com">info@maverexserv.com</a>
        </Typography>
        <Typography component="p">We will process your request, where possible and subject to the terms and conditions contained herein, within a reasonable period of time after receipt. To protect your privacy and security, we will take reasonable steps to help verify your identity before granting access or making corrections.</Typography>
        <Typography component="p">In compliance with domestic and international data protections legislation, we can remove all data associated with a user account upon a user’s request. For users under 18, data retention is subject to stricter controls, and personal data will be deleted upon reaching the age of majority, or upon request by the minor&apos;s parent or guardian, unless required for legal compliance. </Typography>

        <Typography component="h6" variant="h5">Security</Typography>
        <Typography component="p">We prioritize the security of our Account holders&apos; information. When you submit personal details to our Site, we protect them both online and offline. We thoroughly protect sensitive information, such as bank or credit card details, is encrypted with SSL during transmission. While we use SSL for online protection, we also safeguard all other user data offline. The third-party servers we use to store personal data are secured with physical and technical measures to prevent loss, misuse, or alteration.</Typography>
        <Typography component="p">However, please note that no Internet transmission can be completely secure. While we take all reasonable precautions, we cannot guarantee absolute security, and data transmission on our Site is at your own risk. Access to personal information is restricted to employees and third-party agents who need it to perform their duties. Our employees are committed to maintaining the privacy and security of all user information and face disciplinary actions if they fail to adhere to our policies.</Typography>
        <Typography component="p">In the event of a security breach involving your personal information, we will notify you and the appropriate regulatory agencies as required by law. Notification will be sent via email, though it may be delayed to accommodate law enforcement needs, assess the damage, and implement remedies.</Typography>
        <Typography component="p">By providing your personal information to us, you acknowledge that you do so with informed consent and at your own risk.</Typography>

        <Typography component="h6" variant="h5">Eligibility</Typography>
        <Typography component="p">We do not knowingly collect data from individuals under the age of 18. To ensure compliance with applicable laws, including the Kids Online Safety Act (KOSA), users must verify their age during account creation. If we discover that a minor has provided personal data without proper consent, we will take immediate steps to delete such information. </Typography>
        <Typography component="p">We encourage parents and guardians to spend time online with their children and to participate and monitor the interactive activities of their children. We provide tools to enable parents to monitor their child&apos;s activity on our platform. If you believe that your child&apos;s privacy has been violated, please contact us immediately. </Typography>
        <Typography component="p">Under the Kids Online Safety Act (KOSA), additional protections apply to users under 18 years old. We have implemented specific measures to safeguard their data, including:</Typography>
        <List>
          <ListItem>
            Limiting the collection and use of personal data to what is necessary for the intended service.
          </ListItem>
          <ListItem>
            Preventing the sharing of geolocation data unless explicitly
            required and consented to by the user and their guardian
          </ListItem>
          <ListItem>
            Restricting interactions between minors and unknown adults on our platform
          </ListItem>
        </List>
        <Typography component="p">We ensure that all advertisements and promotions targeting minors are appropriate for their age group. We also implement features that prevent unsolicited contact from adults to minors.</Typography>

        <Typography component="h6" variant="h5">External Websites</Typography>
        <Typography component="p">We are not liable for the privacy policies of external websites linked to ours.</Typography>

        <Typography component="h6" variant="h5">Access and Correction</Typography>
        <Typography component="p">
          You can request a copy of your data or request corrections by contacting us at:&nbsp;
          <a href="mailto:info@maverexserv.com">info@maverexserv.com</a>
        </Typography>

        <Typography component="h6" variant="h5">Policy Changes</Typography>
        <Typography component="p">We regularly review and update our Privacy Policy. If changes are made, a notice will be posted on our website. Continued use of the service after the notice has been posted will be deemed acceptance of the new terms and conditions.</Typography>

        <Typography component="h6" variant="h5">Governing Law</Typography>
        <Typography component="p">This Agreement is governed by the laws of Hungary. However, we also comply with the applicable data protection laws and regulations in the EU and the USA, including the Kids Online Safety Act (KOSA) in the United States.</Typography>
        <Typography component="p">Any claim or dispute arising out of or in connection with the formation or subject matter of this Agreement (including non-contractual claims or disputes) will be governed and interpreted according to the laws of Hungary. The courts of Hungary have exclusive jurisdiction to hear, determine, and review any dispute, lawsuit, claim, or action that may arise under or outside of this Agreement. The parties’ consent to the personal jurisdiction and chosen venue for resolving disputes between them.</Typography>

        <Typography component="h6" variant="h5">Questions</Typography>
        <Typography component="p">
          For any questions or clarifications regarding the provisions listed above,
          please contact us via email at&nbsp;
          <a href="mailto:info@maverexserv.com">info@maverexserv.com</a>
        </Typography>

        <Typography component="p">Effective Date:</Typography>
        <Typography component="p">
          This Privacy Policy is effective from 22.08.2024
        </Typography>
      </Box>
    </Container>
  );
};

export default Privacy;
