// @flow
import React, { type StatelessFunctionalComponent } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  InputAdornment,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Lock, LockOpen } from "@mui/icons-material";

type Props = {
  tracking: {
    value: string,
    isEditable: boolean,
  },
  onChange: ({ value: string, isEditable: boolean }) => mixed,
}
const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    gap: "20px",
  },
  url: {
    flex: 1,
  },
  button: {
    width: "182px",
    marginTop: "23px",
  },
}));

const PostbackUrls: StatelessFunctionalComponent<Props> = ({ tracking, onChange }: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.url}>
        <FormControl fullWidth>
          <FormLabel>Tracking code</FormLabel>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            onChange={({ target: { value } }) => onChange({ ...tracking, value })}
            value={tracking.value}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {tracking.isEditable ? (
                    <LockOpen className={classes.icon} />
                  ) : (
                    <Lock className={classes.icon} />
                  )}
                </InputAdornment>
              ),
            }}
            disabled={!tracking.isEditable}
            helperText="See &quot;how to&quot; block below for details"
          />
        </FormControl>
      </Box>

      <Button
        className={classes.button}
        variant="main"
        onClick={() => onChange({ ...tracking, isEditable: !tracking.isEditable })}
      >
        {tracking.isEditable ? "Save changes" : "Edit tracking"}
      </Button>
    </Box>
  );
};

export default PostbackUrls;
