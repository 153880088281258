// @flow
/* eslint-disable import/max-dependencies */
import React, { type StatelessFunctionalComponent } from "react";
import {
  AppNavSidebar as AppNavSidebarComponent,
  AppNavSidebarMenuComponent,
  type AppNavSidebarMenuComponentUrl,
  type AppNavSidebarProps,
  type AppNavSidebarMenuComponentProps,
} from "@fas/cpa-cabinet-ui";
import { type Theme, Toolbar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Assessment, Home, Star } from "@mui/icons-material";
import PostbackUrl from "../../icons/PostbackUrl";

type Props = {
  isOpen: boolean,
}

const useMenuListStyles: () => $PropertyType<AppNavSidebarMenuComponentProps, "classes"> = makeStyles((theme: Theme): $PropertyType<AppNavSidebarMenuComponentProps, "classes"> => ({
  menuItem: {
    ...theme.typography.body,
    padding: "10px",
    borderLeft: "2px solid transparent",
    display: "flex",
    alignItems: "center",
    "&.Mui-selected": {
      backgroundColor: theme.palette.text.secondary,
      borderLeftColor: theme.palette.text.main,
      color: theme.palette.background.dark,
      "& .MuiListItemText-root": {
        color: theme.palette.background.dark,
      },
    },
  },
  menuItemIcon: {
    color: theme.palette.background.dark,
  },
  menuItemText: {
    color: theme.palette.text.mutted,
  },
}));

const useDrawerStyles: () => $PropertyType<AppNavSidebarProps, "classes"> = makeStyles((): $PropertyType<AppNavSidebarProps, "classes"> => ({
  // $FlowFixMe
  paper: {
    justifyContent: "flex-start",
    background: "rgba(180, 90, 212, 0.05)",
  },
  // $FlowFixMe
  drawer: {
    "& .MuiList-root": {
      padding: 0,
    },
  },
  // $FlowFixMe
  drawerOpen: {
    width: "212px",
  },
  // $FlowFixMe
  drawerClose: {
    width: "44px",
  },
}));

const useStyles = makeStyles(() => ({
  toolbar: {
    minHeight: "60px",
    padding: 0,
  },
}));

const AppNavSidebar: StatelessFunctionalComponent<Props> = ({
  isOpen,
}: Props) => {
  const drawerClasses: $PropertyType<AppNavSidebarProps, "classes"> = useDrawerStyles();
  const classes = useStyles();
  const menuListClasses: $PropertyType<AppNavSidebarMenuComponentProps, "classes"> = useMenuListStyles();
  const links: Array<AppNavSidebarMenuComponentUrl> = [
    { url: "/dashboard", text: "Dashboard", icon: <Home /> },
    { url: "/transactionReport", text: "Transaction Report", icon: <Assessment /> },
    { url: "/activeBrands", text: "Active Brands", icon: <Star /> },
    { url: "/postbacks", text: "Postback URLs", icon: <PostbackUrl /> },
  ];

  const Offset = () => <Toolbar className={classes.toolbar} />;

  return (
    <AppNavSidebarComponent
      permanentIconsVisible
      isOpen={isOpen}
      classes={drawerClasses}
    >
      <Offset />
      <AppNavSidebarMenuComponent links={links} classes={menuListClasses} />
    </AppNavSidebarComponent>
  );
};

export default AppNavSidebar;
