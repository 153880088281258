// @flow
import React, { type StatelessFunctionalComponent, type Node } from "react";
import {
  Box, Collapse, Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

type Props = {
  title: string,
  children: Node,
}
const useStyles = makeStyles(() => ({
  collapseTitle: {
    cursor: "pointer",
  },
}));

const CollapseCard: StatelessFunctionalComponent<Props> = ({ title, children }: Props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ marginTop: "40px", padding: "40px", border: "1px solid #D9D9D9" }}>
      <Box onClick={handleClick} className={classes.collapseTitle}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">{title}</Typography>
          {open ? <ExpandLess sx={{ color: "rgba(0,0,0,0.3)" }} /> : <ExpandMore sx={{ color: "rgba(0,0,0,0.3)" }} />}
        </Box>
      </Box>
      <Collapse in={open}>
        <Box sx={{ marginTop: "20px" }}>
          {children}
        </Box>
      </Collapse>
    </Box>
  );
};

export default CollapseCard;
