// @flow
import React, {
  type StatelessFunctionalComponent, useState,
} from "react";
import {
  Box, Button, TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { login } from "../../services/request";

type Props = {
  onNext: ({expires: number, token: string}) => mixed,
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root + .MuiFormControl-root": {
      marginTop: "20px",
    },
  },
  input: {
    "& .MuiInputBase-root": {
      border: "none",
      borderRadius: "0",
      background: theme.palette.background.light,
      "& input": {
        "WebkitTextFillColor": "#fff",
      },
      "&:hover": {
        background: theme.palette.background.default,
      },
      "&.Mui-focused": {
        background: "#fff",
        color: theme.palette.text.main,
        "& input": {
          "WebkitTextFillColor": theme.palette.text.main,
          "&[value=\"\"]": {
            "WebkitTextFillColor": "rgba(0, 0, 0, 0.25)",
          },
        },
      },
      "& .MuiOutlinedInput-notchedOutline": {
        display: "none",
      },
    },
    "& .MuiFormHelperText-root": {
      color: "#FFF!important",
    },
  },
  button: {
    width: "160px",
    margin: "40px auto 0",
  },
}));

const AuthField: StatelessFunctionalComponent<Props> = ({ onNext }: Props) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    login: "",
    password: "",
  });
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((data) => ({ ...data, [name]: value }));
    setErrorMessage("");
  };

  const onKeyPress = ({ charCode }: { charCode: number }) => {
    const enterCharCode: number = 13;
    if (charCode === enterCharCode) {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    login(formData).then((r) => {
      onNext(r.data.payload);
    }).catch((r) => {
      const message: string = (r && r.response && r.response.data && r.response.data.message) || "Error";
      setErrorMessage(message);
      setLoading(false);
    });
  };

  return (
    <Box onKeyPress={onKeyPress} className={classes.root}>
      <TextField
        required
        fullWidth
        autoFocus
        placeholder="Input your User Name"
        size="small"
        type="text"
        name="login"
        value={formData.login}
        onChange={handleChange}
        className={classes.input}
        error={Boolean(errorMessage)}
      />
      <TextField
        required
        fullWidth
        size="small"
        placeholder="Input your password"
        type="password"
        name="password"
        value={formData.password}
        onChange={handleChange}
        className={classes.input}
        error={Boolean(errorMessage)}
        helperText={errorMessage}
      />
      <Button
        variant="primary"
        name="next"
        onClick={handleSubmit}
        disabled={loading}
        className={classes.button}
      >
        <Box width="100%">Next</Box>
      </Button>
    </Box>
  );
};

export default AuthField;
