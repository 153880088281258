// @flow
import React from "react";
import { Box, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { EventPostback } from "@fas/cpa-cabinet-ui";
import type { EventPostbackType } from "@fas/cpa-cabinet-ui/lib/EventPostback/EventPostback.types";

export const eventTypeOptions = [
  {
    title: "Info",
    value: "info",
  },
  {
    title: "Negative",
    value: "negative",
  },
];

export const eventNameOptions = {
  info: [
    {
      title: "Registration",
      value: "registration",
    },
    {
      title: "Confirm",
      value: "confirm",
    },
    {
      title: "Payment",
      value: "payment",
    },
    {
      title: "Try",
      value: "try",
    },
  ],
  negative: [
    {
      title: "Chargeback",
      value: "chargeback",
    },
    {
      title: "Decline",
      value: "decline",
    },
  ],
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "flex-end",
    gap: "10px",
    margin: 0,
    width: "100%",
    "& .MuiGrid-item": {
      padding: 0,
    },
    "& .MuiFormLabel-root p": {
      fontSize: "inherit",
    },
  },
  action: {
    marginLeft: "10px",
  },
  buttonDelete: {
    marginRight: "20px",
    display: "flex",
    alignItems: "center",
    height: "40px",
    fontSize: "14px",
    fontWeight: "600",
    padding: "0 24px",
    borderRadius: "20px",
    textTransform: "capitalize",
    boxShadow: "none",
    color: theme.palette.text.primary,
    border: `1px solid ${theme.palette.text.primary}`,
    "&:hover": {
      boxShadow: "none",
      backgroundColor: theme.palette.text.primary,
      color: "#fff",
    },
  },
  button: {
    minWidth: "max-content",
    display: "flex",
    alignItems: "center",
    height: "40px",
    fontSize: "14px",
    fontWeight: "600",
    padding: "0 24px",
    borderRadius: "20px",
    textTransform: "capitalize",
    boxShadow: "none",
    color: "#fff",
    backgroundColor: theme.palette.text.primary,
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "#8E00C0",
    },
  },
}));

type Props = {
  items: Array<EventPostbackType>,
  onChange: (Array<EventPostbackType>) => mixed,
}

const EventPostbackComponent = ({
  items,
  onChange,
}: Props) => {
  const classes = useStyles();
  const someEdited = items.some(({ isEditable }) => isEditable);
  const handleUpdateEventsPostback = (index: number, item: EventPostbackType) => {
    if (items[index].type !== item.type) {
      // eslint-disable-next-line no-param-reassign
      item.name = "";
    }
    onChange([...items.slice(0, index), item, ...items.slice(index + 1)]);
  };

  const handleAddEventsPostback = (item: EventPostbackType) => {
    onChange([...items, item]);
  };

  const handleSaveEventPostback = (index: number, item: EventPostbackType) => {
    onChange([...items.slice(0, index), item, ...items.slice(index + 1)]);
  };

  const handleDeleteEventPostback = (index: number) => {
    onChange([...items.slice(0, index), ...items.slice(index + 1)]);
  };
  return (
    <Box>
      {items.map((postback: EventPostbackType, index: number) => (
        <EventPostback
          key={postback.id}
          data={postback}
          onChange={(item: EventPostbackType): mixed => handleUpdateEventsPostback(index, item)}
          onSave={(item: EventPostbackType): mixed => handleSaveEventPostback(index, item)}
          onDelete={(): mixed => handleDeleteEventPostback(index)}
          disabled={someEdited}
          typeOptions={eventTypeOptions}
          nameOptions={eventNameOptions[postback.type] || []}
          classes={classes}
        />
      ))}

      { items.length < 5 && (
        <Button
          sx={{ marginTop: "20px" }}
          disabled={someEdited}
          variant="main"
          onClick={() => handleAddEventsPostback({
            id: Math.random().toString(16).slice(2),
            name: "",
            type: "",
            url: "",
            isEditable: true,
          })}
        >
          Add events postback
        </Button>
      )}
    </Box>
  );
};

export default EventPostbackComponent;
