// @flow
// eslint-disable max-len
import React, { type StatelessFunctionalComponent } from "react";
import {
  Container, Box, List, ListItem, Typography, type Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

type Classes = {
  root: string,
}

const useStyles: () => Classes = makeStyles((theme: Theme): {[key: $Keys<Classes>]: mixed} => ({
  root: {
    marginBottom: "48px",
    "& h2": {
      marginTop: "18px!important",
    },
    "& .MuiTypography-h5": {
      margin: "40px 0 10px",
    },
    "& a": {
      color: theme.palette.text.primary,
    },
    "& p": {
      margin: "10px 0",
    },
    "& .MuiList-root": {
      listStyleType: "disc",
      paddingLeft: "16px",
      "&.listNumber": {
        listStyleType: "decimal",
      },
      "& li": {
        display: "list-item",
      },
    },
  },
}));

const Terms: StatelessFunctionalComponent<{}> = () => {
  const classes: Classes = useStyles();

  return (
    <Container>
      <Box className={classes.root}>
        <Typography component="h2" variant="h5">TERMS OF SERVICE</Typography>

        <Typography component="p">Effective Date: 22.08.2024</Typography>
        <Typography component="p">To join the Maverex Partner Program, you must agree to the following terms:</Typography>
        <Typography component="p">
          These&nbsp;
          <b>Partner</b>
          &nbsp;Terms and Conditions (the “Agreement”) outline the terms and conditions between
          Maverex Services Kft,located at 2161 Csomád, Leventeutca 14/a, Hungary (“Maverex”),
          and you, the applicant, regarding your participation as a partner of Maverex (“Partner”).
          This includes the establishment of links from your website to our website,&nbsp;
          <a href="//maverexplatform.com">www.maverexplatform.com</a>
          &nbsp;, and its subdomains and services.
        </Typography>
        <Typography component="p">By submitting an application to join or participating in the Maverex Partner Marketing Program, you acknowledge that you have read and agree to be bound by this agreement.</Typography>
        <Typography component="p">This Agreement is between Maverex Services Kft (&ldquo;Maverex&ldquo;) and the individual or entity applying to the Maverex Partner Program and completing the registration form (&ldquo;Application Form&ldquo;) (&ldquo;Partner&ldquo; or &ldquo;you&ldquo;).</Typography>

        <Typography component="h6" variant="h5">Definitions:</Typography>
        <List className="listNumber">
          <ListItem>
            <b>Maverex Services kft (Maverex)</b>
            &nbsp;– The owner of the partner program and the online platform used by partners and advertisers.
          </ListItem>
          <ListItem>
            <b>Partner</b>
            &nbsp;– Any individual or entity (including agents, employees, representatives,
            and others acting on their behalf)who has subscribed or registered on
            the Maverex Services kft partner website to provide services.
          </ListItem>
          <ListItem>
            <b>Maverex Partner Marketing Program (Partner  Program)</b>
            &nbsp;– A program owned by us that can be joined as a partner through Maverex Services kft.
          </ListItem>
          <ListItem>
            <b>Partner’s Account</b>
            &nbsp;– The virtual account provided to the Partner by Maverex Services kft.
          </ListItem>
          <ListItem>
            <b>Program Web Sites</b>
            &nbsp;– Websites that are accessible to partners and
            featured through Maverex Services kft and Partner Programs.
          </ListItem>
          <ListItem>
            <b>Links</b>
            &nbsp;– Textual and/or graphic connections to the Program Web Sites or other promotional content.
          </ListItem>
          <ListItem>
            <b>Commission</b>
            &nbsp;– The fee amount per conversion to be paid by Maverex Services kft to the Partner.
          </ListItem>
          <ListItem>
            <b>Media</b>
            &nbsp;– Platforms through which advertising campaigns can be conducted
            (e.g., websites owned or controlled by you, emails clearly identified as from you,
            other legitimate online advertising methods).
          </ListItem>
          <ListItem>
            <b>Qualified Action</b>
            &nbsp;– a specific user action where a sale is completed. This means that the advertiser pays
            the publisher or a partner commission only when a user makes a purchase through
            their referral link according to the Pay Per Sale (PPS) model.
          </ListItem>
        </List>

        <Typography component="p">The following terms and conditions constitute a binding agreement (the “Agreement”) between you (“Partner” or “you”), the individual or entity applying to join Maverex Services kft, and Maverex Services kft (“Company,” “we,” or “us”). These provisions apply to your participation in the Partner Programs offered through Maverex Services kft and your use of Maverex Services kft.</Typography>

        <Typography component="p">By submitting an application and/or accessing or using Maverex Services kft, you provide direct consent to all terms and conditions contained in this Agreement.</Typography>

        <Typography component="h6" variant="h5">1. Participation Procedure </Typography>
        <Typography component="p"><b>1.1. Application and Account Setup</b></Typography>

        <Typography component="p">
          1.1.1. To apply for our Partner Program, submit your request through our website at&nbsp;
          <a href="//maverexplatform.com">www.maverexplatform.com</a>
          . The application form must be completed with the highest level of accuracy and honesty.
          The use of fake names, aliases, or pseudonyms to conceal your identity or contact details
          is strictly prohibited. You agree to keep your contact information current and notify us of any
          changes upon request. You also acknowledge that your account is for your use only and should not be
          accessed by any other person or entity.
          Likewise, you agree not to use anyone else&apos;s account for your own purposes.
        </Typography>
        <Typography component="p">1.1.2.  If you are an individual rather than a trading entity, you must be at least 18 years old to register and participate as a partner. If you reside or conduct business in a jurisdiction where the legal age of adulthood is greater than 18, you must meet the legal age requirement of that jurisdiction. We may apply age verification system in the registration process for individuals. </Typography>
        <Typography component="p">1.1.3. Your use of Maverex Services kft is subject to our approval. We reserve the right to reject your application for any reason, including but not limited to the determination that your promotional methods or traffic sources are unsuitable for the Partner Programs, or if we suspect you may violate this agreement.</Typography>
        <Typography component="p">1.1.4.  If you are based in, or we determine that you have connections to any countries listed on the OFAC, FATF Blacklist, FATF Greylist, Blacklist EU, UN Security Council sanctions regimes, or EU Financial Sanctions consolidated List of persons, groups, and entities, your application will be automatically rejected.</Typography>

        <Typography component="p">Forbidden Territories include:</Typography>
        <List>
          <ListItem>
            FATF Greylist:&nbsp;
            <a href="https://www.fatf-gafi.org/en/publications/High-risk-and-other-monitored-jurisdictions/increased-monitoring-june-2024.html">https://www.fatf-gafi.org/en/publications/High-risk-and-other-monitored-jurisdictions/increased-monitoring-june-2024.html</a>
          </ListItem>
          <ListItem>
            FATF Blacklist:&nbsp;
            <a href="https://www.fatf-gafi.org/en/publications/High-risk-and-other-monitored-jurisdictions/Call-for-action-june-2024.html">https://www.fatf-gafi.org/en/publications/High-risk-and-other-monitored-jurisdictions/Call-for-action-june-2024.html</a>
          </ListItem>
          <ListItem>
            Blacklist EU:&nbsp;
            <a href="https://www.consilium.europa.eu/en/policies/eu-list-of-non-cooperative-jurisdictions/">https://www.consilium.europa.eu/en/policies/eu-list-of-non-cooperative-jurisdictions/</a>
          </ListItem>
          <ListItem>
            OFAC:&nbsp;
            <a href="https://sanctionssearch.ofac.treas.gov/">https://sanctionssearch.ofac.treas.gov/</a>
          </ListItem>
          <ListItem>
            UN Security Council sanctions regimes:&nbsp;
            <a href="https://www.un.org/securitycouncil/sanctions/information">https://www.un.org/securitycouncil/sanctions/information</a>
          </ListItem>
        </List>

        <Typography component="p">1.1.5. We reserve the right to terminate or suspend your participation in any Partner Program at any time for any reason, in accordance with the terms of this Agreement.</Typography>
        <Typography component="p">1.1.6. Upon acceptance of your application, you will receive login details for your Partner Account. You must change your password and verify the accuracy of your account information upon your initial login.</Typography>
        <Typography component="p">1.1.7. Once approved, you will have access to the relevant Partner Program&apos;s graphic and text links to the Program Web Sites and other promotional materials. These materials may be used in emails, clearly indicating that they are from you, displayed on websites you own or control, or included in online advertisements. You will be identified as a member of the Partner Program through these Links, creating a connection from your Media to the Program Web Site.</Typography>

        <Typography component="p"><b>1.2. Your Warranties, Compliance, and Monitoring </b></Typography>
        <Typography component="p">1.2.1. By entering this Agreement, you affirm, represent, and warrant the following to us:</Typography>
        <List>
          <ListItem>
            You neither reside in nor are located in any of the Forbidden Territories.
            Additionally, you have no commercial or personal ties to any individual or entity in these regions.
          </ListItem>
          <ListItem>
            All the information you have provided in your application and within your
            Partnership Account is truthful, current, and complete.
          </ListItem>
          <ListItem>
            If you operate a partnership network, you will ensure that all your partners fully comply
            with the terms of this Agreement and take responsibility for their adherence.
          </ListItem>
          <ListItem>
            You must implement and maintain robust age verification systems on any platforms or your
            partners’ platforms you deal with to ensure that minors do not access adult content.
            Additionally, all advertising content must be free of material that could be harmful to minors,
            including explicit adult material, violence, or any other content prohibited under applicable laws,
            including the Kids Online Safety Act (KOSA).
          </ListItem>
          <ListItem>
            You must comply with applicable data protection laws, including, but not limited to GDPR and CCPA,
            when collecting and processing personal data. Special care must be taken to protect
            the privacy of minors, and no geolocation data or personal information of minors
            should be collected or shared without proper consent.
          </ListItem>
          <ListItem>
            In course of your activity and dealing with your partners you fully adhere all current international
            rules and laws regulating the content placement, including all AI-generated content restrictions,
            particularly the EU and the USA regulations,
            and you monitor the world acting legislation in this field from time to time.
          </ListItem>
          <ListItem>
            You possess the authority and legal capacity to enter into this binding Agreement
            on your own behalf or on behalf of the entity you represent.
          </ListItem>
          <ListItem>
            You will maintain the confidentiality of your account login details,
            ensuring they are not shared with anyone else.
            You acknowledge that you are fully responsible for all activities that occur under your account.
            Should your login information be stolen or used without authorization, you agree to notify us immediately.
          </ListItem>
        </List>

        <Typography component="p">1.2.2. We reserve the right to monitor the content you deal with and practices you use in your work to ensure compliance with this Agreement and all applicable laws. Any violations of the Kids Online Safety Act (KOSA) or other relevant regulations may result in the immediate suspension or termination of the partnership and potential legal action.</Typography>

        <Typography component="h6" variant="h5">2. Payments/ Commissions</Typography>
        <Typography component="p">2.1. Under the terms of this Agreement, we will compensate the Partner for each Qualified Action (the “Commission”). A “Qualified Action” is defined as a natural person who:</Typography>
        <List className="listNumber">
          <ListItem>
            Accesses the Program Web Site via the Partner&apos;s Link, with the
            Link being the final referral to the Program Web Site.
          </ListItem>
          <ListItem>
            Is not identified as artificial or fraudulent traffic. This includes,
            but is not limited to, automated access, spiders, robots, requests generated in emails
            or chat rooms, script-generated clicks, links on unauthorized websites,
            and clicks not generated by a browser or not initiated by a genuine visitor
            intentionally navigating to a specific site.
          </ListItem>
          <ListItem>
            Does not utilize pre-filled fields in the submission forms.
          </ListItem>
          <ListItem>
            Completes all required information for the action within the specified
            time frames set by us and/or our Partners.
          </ListItem>
          <ListItem>
            Is not later determined by us and/or our Partners to be incomplete,
            fraudulent, unqualified, or a duplicate entry.
          </ListItem>
          <ListItem>
            Does not subsequently request a refund, initiate a chargeback, or reverse a payment.
            Additionally, actions originating from certain countries may be rejected
            if they do not involve a valid transaction or sale.
          </ListItem>
        </List>

        <Typography component="p">2.2. We reserve the right to reject any action that does not meet the criteria for a Qualified Action, as defined and updated in this Agreement. For Partner Programs that are based on sales (i.e., Pay Per Sale), we have the authority to take the following measures at the request of our partners: If a Partner receives a commission for a Qualified Action but the corresponding sale is later refunded, the refunded commission amount may be deducted from any future commissions owed to the Partner. Additionally, if an end-user subscribes using a prepaid card that cannot be rebilled, the Partner will only receive the actual payment amount, not the standard Pay Per Sale commission.</Typography>

        <Typography component="p">2.3. During the first month of collaboration, all new Partners will receive payments on a monthly basis (Net30). Our cooperation with the Partner will commence as soon as traffic is initiated. After the initial month, commission payments will continue on a Net30 schedule, provided that your account generates more than $2000 per month (it means at least $500 per week) for Wire Transfer, Paxum, and PayPal. If your weekly earnings exceed $1500 through Wire Transfer, Paxum, or PayPal, you may be eligible for weekly payments, contingent upon an agreement with your account manager. Should your weekly earnings fall below the $1500 threshold even once, your payment plan will revert to a Net30 basis. To qualify for weekly payments (Net7) again, you must consistently meet the $1500 weekly threshold for five consecutive weeks.</Typography>
        <Typography component="p">If your commissions exceed $2000, the responsibility for bank transaction fees will be shared equally between you and the Company. If any Qualified Action does not meet the criteria specified in paragraph 2.1, we reserve the right to reverse payments for such actions. Please note that our relationship is not one of employment; as a Partner, you are an independent contractor and are solely responsible for paying any social security contributions, fees, and/or taxes related to income earned through the Company.</Typography>

        <Typography component="p">
          2.4. Based on the currency you select when setting up your account, commissions may be paid in USD,
          EUR, or GBP. If payments are made in a currency different from your chosen one, they will be
          automatically converted into the currency associated with your account using
          the exchange rates provided at&nbsp;
          <a href="http://www.xe.com/currencytables">http://www.xe.com/currencytables</a>
          &nbsp;on the date of the Qualified Action.
        </Typography>

        <Typography component="p">2.5. An invoice will be automatically generated on your behalf for all Commissions payable under this Agreement, and payments will be made based on that invoice. We will, at our sole discretion, track all Links and record Qualified Actions and Commissions. If you wish to dispute any part of the invoice in good faith, you must submit the dispute to us in writing, with sufficient detail and all available evidence, within thirty (30) days of the invoice date. Failure to dispute the invoice within this time frame means you irrevocably waive all claims related to that invoice. All payments are based on the statistics from our tracking system. Our partner managers’ team will review all disputes regarding these statistics.</Typography>

        <Typography component="p">2.6. If you have an outstanding balance owed to us under this Agreement or any other agreement between us, whether or not it relates to your Partner Account or the Partner Program, you agree that we may deduct such amounts from the funds payable to you under this Agreement.</Typography>

        <Typography component="p">2.7. If you provide incorrect, inaccurate, or outdated billing information for commission payments, and we incur bank charges or fees to refund the commission sent to the wrong account, we reserve the right to recover these costs from you. This recovery will be considered a Fine. Fines will be applied in the following circumstances:</Typography>
        <List>
          <ListItem>
            You fail to notify us or your personal manager promptly after changing your payment details.
          </ListItem>
          <ListItem>
            The payment details provided are incorrect, inaccurate, or outdated.
          </ListItem>
          <ListItem>
            Changes to your payment details are made untimely (after a payment has already been sent, etc.).
          </ListItem>
          <ListItem>
            Any other situation where additional costs arise due to your error.
          </ListItem>
        </List>

        <Typography component="p">2.8. The Fine amount will vary depending on the invoice total:</Typography>
        <List>
          <ListItem>
            For invoices up to $5,000, the fine will be $50 or its equivalent in the payment currency.
          </ListItem>
          <ListItem>
            For invoices between $5,000 and $10,000, the fine will be $100 or its equivalent in the payment currency.
          </ListItem>
          <ListItem>
            For invoices over $10,000, the fine amount will be determined on a case-by-case basis but
            will not be less than $200 or its equivalent in the payment currency.
          </ListItem>
        </List>

        <Typography component="p">2.9. Commission earnings may not be paid out, and the account balance may be reset or closed at our discretion under the following conditions:</Typography>
        <List>
          <ListItem>
            If a Partner provides incorrect payment details, fails to update their payment information
            in the Partner&apos;s profile, or fails to request payment of earned commissions within
            one year after reaching the minimum payout threshold (depending on the payment method).
          </ListItem>
          <ListItem>
            If the Partner does not reach the minimum payout threshold of (depending on the payment method)
            within six months of the first qualifying action (the entered lead).
          </ListItem>
        </List>

        <Typography component="p">2.10. If a Partner&apos;s account remains inactive for six months and the Partner does not request payment of the earned commissions within this period, any and all commissions may be forfeited and written off the balance, and the account may be terminated.</Typography>

        <Typography component="h6" variant="h5">3. Rules and Obligations for Partners</Typography>
        <Typography component="p">3.1. In addition to any other warranties provided in this Agreement, you explicitly acknowledge, agree to, and commit to the following responsibilities, by representing and warranting to us in good faith:</Typography>

        <Typography component="p">(a) You are fully accountable for the operation, creation, and upkeep of all content on your Media, as well as any content linked to your Media. You guarantee that all content posted on your Media or elsewhere, related to any Partner Program:</Typography>
        <List className="listNumber">
          <ListItem>
            Does not violate any appropriate law of any country;
          </ListItem>
          <ListItem>
            Does not infringe on any third-party personal or intellectual property rights;
          </ListItem>
          <ListItem>
            Does not include, reference, or link to any material that is unlawful, harmful, defamatory,
            threatening, sexually explicit, inappropriate for minors, obscene, promotes violence, harassing,
            discriminatory (based on religion, nationality, gender, ethnicity, race, disability, or age),
            contains profanity, or is otherwise deemed unacceptable by us or any Partner
            (either through us or directly) and is considered damaging to our or any Partner&apos;s reputation
            (collectively referred to as &ldquo;Prohibited Content&ldquo;).
          </ListItem>
        </List>

        <Typography component="p">(b) You will not make any statements, representations, or warranties about us, Detailed Graphics kft, Program Websites, Partners, or any related services or products offered by us or our Partners, except as expressly permitted in this document.</Typography>

        <Typography component="p">
          (c) Your Media must not imitate or replicate the look and feel of&nbsp;
          <a href="http://maverexplatform.com/">http://maverexplatform.com/</a>
          &nbsp;or any Program Website, nor give the impression that your media is
          endorsed by us or any Partner without prior written consent.
        </Typography>

        <Typography component="p">(d) You will comply with all:</Typography>
        <List>
          <ListItem>
            terms, restrictions, and obligations stated in this Agreement;
          </ListItem>
          <ListItem>
            applicable laws and regulations, including those related to the collection,
            processing, and transfer of personal data such as the European General Data Protection Regulation
            (GDPR), as they apply to your obligations under this Agreement, your business,
            your participation in AdsEmpire.com and Partner Programs, your Media, or the use of Links;
          </ListItem>
          <ListItem>
            terms, conditions, policies, guidelines, or other legal documents of any third-party services
            you use in connection with the Partner Program, including but not limited to
            social networking services, email providers, and ad networks.
          </ListItem>
        </List>

        <Typography component="p">(e) You will not place ads or media related to the Program Website on any online auction platforms (such as Amazon, eBay, or similar sites).</Typography>

        <Typography component="p">3.3. Specific terms for each type of program are outlined as follows:</Typography>

        <Typography component="p">
          3.3.1. Email Campaigns: (a) Using emails or links within emails to advertise is strictly forbidden.
          If you or anyone acting on your behalf violates this rule, we have the right to suspend or terminate
          your account immediately without prior notice or payment. If you plan to promote any
          of the Program Websites via email, contact us at&nbsp;
          <a href="mailto:info@maverexserv.com">info@maverexserv.com</a>
          . Unauthorized email promotions, such as spamming, will result in an immediate ban
          from Maverex Services Kft and Program Websites, and no payment will be made to you.
          (b) For any approved email campaigns, you must download the &quot;Suppression List&quot; from
          Maverex Services Kft. You are required to remove any entries from the list based on the
          latest updates and use only the remaining addresses in your email list.
          An opt-out method must be included in all Links, and if you receive opt-out requests directly,
          they must be forwarded to us at&nbsp;
          <a href="mailto:info@maverexserv.com">info@maverexserv.com</a>
          &nbsp;as soon as possible. Your emails containing links may not include any other content, except
          for content required by law. (c) You acknowledge and agree that failing to update the Suppression List
          and remove all relevant emails from your database before mailing may result in commission withholding,
          account suspension from some or all Partner Programs, potential legal action, and other remedies as
          stated in this Agreement. You also guarantee that you will not use suppression files created by
          Maverex Services Kft for sending emails, as violating this rule may lead to the consequences mentioned above.
        </Typography>

        <Typography component="p">3.3.2. Advertising Campaigns: (a) Unless specifically agreed between product owners and Maverex Services Kft, Links should not be placed on bulletin boards or chat rooms. During promotion campaigns, chat traffic should not be advertised. Pop-ups and pop-unders related to the Partner Program must clearly state the page title of the window as served by the Partner. Any client-side software used for advertising must be installed on end-users&apos; devices only if its features and functions are clearly explained before installation. Installation must be done according to an end user license agreement written in plain English, and the software must be easily uninstalled following standard procedures. (b) If a promotion has a limit (CAP) on the maximum payout or the maximum number of leads, clicks, or sales, you agree not to receive payment for any actions that exceed this CAP. It is your responsibility to monitor these Caps.</Typography>

        <Typography component="p">3.3.3. CPA Network Campaigns: You agree to make Links available on your partner network (the &quot;Network&quot;) for use by other partners within your network (each referred to as a &quot;Third-Party Participant&quot;). You consent that Third-Party Participants are prohibited from modifying the Links. Your Network must uphold the highest industry standards, and you must not permit any party to be a Third-Party Participant if their business model or website contains Prohibited Content. All Third-Party Participant must have a positive business relationship with you. Before accessing the Links, all Third-Party Participanta must agree to this Agreement and commit to following its rules. You must immediately cease cooperation with any Third-Party Participant who breaches or is likely to breach this Agreement. If any illegal or dishonest activity is suspected, you must provide Maverex Services Kft with the identity and contact information of the Third-Party Participant. Any Third-Party Participant must be immediately removed from the Partner Program, and their access to future offers managed by Maverex Services Kft on the Web should also be terminated, upon written notification by Maverex Services Kft. You are responsible for all actions or omissions of any Third-Party Participant unless all accurate and complete contact information about them has been provided to Maverex Services Kft, and the Third-Party Participant has agreed to abide by this Agreement as recorded by Maverex Services Kft.  </Typography>

        <Typography component="p">3.3.4. Social Network Usage: You or anyone acting on your behalf, directly or indirectly, is strictly prohibited from using social media sites, including but not limited to Twitter, Facebook, Instagram, and others, to advertise any of the Program&apos;s websites. If it is suspected that any leads were obtained through such channels, violating this provision, such leads will not be compensated.</Typography>

        <Typography component="p">3.4. Mobile Devices and Traffic: (a) Traffic from desktop and tablet devices is only accepted for targeted landing pages. (b) Except for tablets, traffic from Android and iOS devices is only accepted for mobile landing pages. (c) Mobile landing pages are not permitted for iPod touch, iPad, and BlackBerry devices. The list of prohibited devices is updated regularly and can be provided by your Partner manager upon request. You are responsible for ensuring you are familiar with the latest recommendations and restrictions before starting your campaign.</Typography>

        <Typography component="p">3.5. Private Programs: (a) Before launching any private program, approval must be obtained from our Partner managers. (b) Active private campaigns must be paused for 48 hours (including weekends) if requested by your Partner manager. Commissions for leads generated after this period will not be paid. (c) Private programs that have been inactive for more than five calendar days must not be resumed without approval from the Partner manager. (d) Your campaign must commence within one week of receiving the links from your Partner manager. If the campaign does not start within this period, the campaign is considered invalid, and you must confirm the links and request re-approval to resume traffic.</Typography>

        <Typography component="p">3.6. Data Privacy Policy must always be visibly posted, displayed, and accessible to end-users, including before the capture of any personally identifiable data. All data capture must comply with applicable international and local laws, including but not limited to practices related to information collection, use, and sharing, as well as obtaining consent for collecting personally identifiable information in connection with the Partner Program. Such information must be shared with us and Partners to fulfill our obligations to end-users.</Typography>

        <Typography component="h6" variant="h5">4. Intellectual Property Rights and License</Typography>
        <Typography component="p">4.1 You are not allowed to modify or alter Program Websites or company logos in any way. If you need a specific size or format, please send a request via email.</Typography>
        <Typography component="p">4.2 You must avoid purchasing, bidding on, or otherwise acquiring any URLs or domain names that are identical or confusingly similar to the trademarks or service marks of our Partners, whether registered or unregistered, including terms such as Maverex Services Kft.</Typography>
        <Typography component="p">4.3 It is strictly forbidden to create negative websites aimed at driving traffic to programs provided by Maverex Services Kft.</Typography>
        <Typography component="p">4.4 Using our Partners&apos; trademarks, whether registered or unregistered, without prior explicit consent is prohibited.</Typography>
        <Typography component="p">4.5 You are not allowed to copy text, images, or any other content from other websites without the written permission of the website owners. If you find content on other sites useful and want to replicate it, you must rephrase the information in your own words. Failure to comply with this rule may result in the termination of the Partner program.</Typography>
        <Typography component="p">4.6 You may not use text advertisements created by yourself or any third party unless they have been reviewed and approved by the Maverex Services Kft Partner team.</Typography>
        <Typography component="p">4.7 It is prohibited to publish inaccurate information about the services offered by Program Websites that could harm the company&apos;s reputation or lead to customer complaints. Examples include misleading claims like &quot;Offer ends today&quot; or advertising free services that are actually paid.</Typography>
        <Typography component="p">4.8 The use of sexually explicit, adult, pornographic, or otherwise offensive content to promote iOS and Android applications operated by Maverex Services Kft is strictly forbidden. Additionally, using trademarks of Apple, Google, or their subsidiaries, including but not limited to iTunes and Google Play, in relation to such offensive content is prohibited.</Typography>
        <Typography component="p">4.9 Any misuse of third-party copyrights or trademarks is not allowed. If you violate this provision and a third party&apos;s rights are infringed, we will fully support them in pursuing legal action against you.</Typography>
        <Typography component="p">4.10 You are granted a non-exclusive, non-transferable, and revocable license to use the Links and access Program Websites via the Links under the terms and conditions of this Agreement and our policies. This license is to identify your media as a participant in the Partner Program and assist in driving sales through the Program Website. You are not permitted to alter, manipulate, or create derivative works from any tools, graphics, or other materials provided by Maverex Services Kft. Your license can be revoked at any time upon written notice. Except as explicitly stated, this Agreement does not grant you any rights to any of our Partner&apos;s trademarks, service marks, patents, copyrights, or trade secrets provided exclusively to Maverex Services Kft.</Typography>
        <Typography component="p">4.11 You agree that Maverex Services Kft has the right to use any comments, suggestions, or recommendations you provide without compensation.</Typography>
        <Typography component="p">4.12 All rights not expressly granted under this Agreement are reserved by Maverex Services Kft.</Typography>

        <Typography component="h6" variant="h5">5. Confidentiality</Typography>
        <Typography component="p">Upon accepting this Agreement, both parties agree that all details related to this Agreement, including but not limited to its terms and conditions, financial and business data, pricing, sales information, and lists of customers and suppliers related to us or our affiliates, are considered confidential business secrets. The parties acknowledge that they are responsible for any penalties resulting from breaching this Agreement, which includes, but is not limited to, any attempts to replicate a similar selection of sponsors and products using the same type of rotation as (name of the company). This confidential information must not be used by you, directly or indirectly, for any purpose other than participating in the Partner Program. The only exceptions to this rule are when such information is publicly known or available from a source other than you.</Typography>

        <Typography component="h6" variant="h5">6. Termination</Typography>
        <Typography component="p">6.1. The terms of this Agreement become effective as soon as your application to the Partner Program is approved and will continue to be in effect until terminated as outlined below.</Typography>
        <Typography component="p">6.2. You have the option to end your participation in the Partner Program at any time. To do so, you must remove all links from your media and delete any copies of those links.</Typography>
        <Typography component="p">6.3. We hold the authority to discontinue your participation in any offers provided by Maverex Services Kft or to terminate this Agreement at any moment, with or without prior notice. This can be done either by deactivating the links or by sending you a written notice.</Typography>
        <Typography component="p">6.4. In the event your account remains inactive for two or more months, it may be closed.</Typography>
        <Typography component="p">6.5. Consequences of Termination</Typography>
        <Typography component="p">6.5.1. Upon termination, whether of your participation in offers from Maverex Services Kft or of this Agreement itself, you are required to immediately cease using all links and must remove all related intellectual property from Maverex Services Kft. You should no longer act as a CPA for any offers under the Maverex Services Kft Network.</Typography>
        <Typography component="p">6.5.2. Even after termination, all rights to legitimate charges, any applicable causes of action, and provisions that are intended to remain in effect will continue to do so. However, if we terminate this Agreement due to your breach, or if we believe you have threatened or intended to breach the terms, you will forfeit any Commissions, regardless of whether they were accrued before the termination date.</Typography>

        <Typography component="h6" variant="h5">Non-Acceptance of Partner</Typography>
        <Typography component="p">7.1. We reserve the right to suspend or terminate your account for a variety of reasons, which include but are not limited to the following:</Typography>
        <Typography component="p">a) You have violated our terms and conditions;</Typography>
        <Typography component="p">b) You are not complying with our email policies and the PPC policies of our partners;</Typography>
        <Typography component="p">c) Your website is using a partner&apos;s trademark to promote a dating site that is not among our approved list;</Typography>
        <Typography component="p">d) Your website is hosted on a free hosting service;</Typography>
        <Typography component="p">e) Your website consists solely of banners with minimal content;</Typography>
        <Typography component="p">f) Your website is still under development;</Typography>
        <Typography component="p">g) Your website is either inaccessible or not functioning properly;</Typography>
        <Typography component="p">h) Your website contains inappropriate or offensive content;</Typography>
        <Typography component="p">i) There is an automatic program redirecting users from your website to a partner&apos;s site;</Typography>
        <Typography component="p">j) Your website offers goods and/or services that are illegal in the jurisdiction where the customer resides, including but not limited to prostitution, drugs, and weapons;</Typography>
        <Typography component="p">k) Your website hosts unlawful photographic material or content;</Typography>
        <Typography component="p">l) You user registration procedure, your data and your content does not comply with the USA, EU and other world data protection legislation, any laws protecting minors’ rights, AI-generated content legislation and other applicable laws. </Typography>

        <Typography component="h6" variant="h5">Remedies</Typography>
        <Typography component="p">8.1. In addition to any other rights and remedies available under this Agreement, Maverex Services Kft reserves the right to delete any activities associated with your links and to freeze or withhold any unpaid commissions or remit payments to your account if:</Typography>
        <Typography component="p">a) Maverex Services Kft determines that you have violated this Agreement;</Typography>
        <Typography component="p">b) We receive complaints regarding your participation in the Partner Program that Maverex Services Kft deems a breach of this Agreement; or </Typography>
        <Typography component="p">c) It is later determined that any Qualified Action does not meet the criteria established in this Agreement or the Partner Program.</Typography>
        <Typography component="p">8.2. The freezing or withholding of commissions or refunds of paid commissions will occur regardless of whether such commissions were earned due to the violation. In the event of a significant breach of this Agreement, Maverex Services Kft reserves the right to disclose your personal and contact information to any third party directly affected by your actions or to the relevant law enforcement or regulatory authorities.</Typography>

        <Typography component="h6" variant="h5">9. Anti-Spam Policy</Typography>
        <Typography component="p">9.1. You must comply with the federal CAN-SPAM Act of 2003, the Data Protection Act 2018 (where applicable), and the General Data Protection Regulation (GDPR). Any emails sent in relation to the Partner Program must include an appropriate opt-out link. Before sending emails containing links or references to the Partner Program, Maverex Services Kft may require you to submit the final version of the email for approval. This can be done by sending it to a Maverex Services Kft agent and obtaining written confirmation.</Typography>
        <Typography component="p">9.2. It is your responsibility to ensure your email complies with the Act. You agree not to depend on AdsEmpire&apos;s approval of your email as proof of compliance with the Act and not to assert compliance based on Maverex Services Kft&apos;s approval.</Typography>
        <Typography component="p">9.3. You are prohibited from using any of the following spam methods:</Typography>
        <List>
          <ListItem>
            Spam in newsgroups
          </ListItem>
          <ListItem>
            Spam in instant messaging
          </ListItem>
          <ListItem>
            Spam in search engines
          </ListItem>
          <ListItem>
            Posting comments with your partner URL on sites you do not own
          </ListItem>
          <ListItem>
            Spam in blogs and wikis
          </ListItem>
          <ListItem>
            Spam with classified ads on the Internet
          </ListItem>
          <ListItem>
            Spam in mobile phone messages
          </ListItem>
          <ListItem>
            Spam on Internet forums
          </ListItem>
          <ListItem>
            Sending chain letters
          </ListItem>
          <ListItem>
            Mass mailing of any kind
          </ListItem>
          <ListItem>
            Spam/junk faxes
          </ListItem>
          <ListItem>
            Spam on file-sharing networks and social networks
          </ListItem>
        </List>

        <Typography component="h6" variant="h5">10. Scams and Fraud</Typography>
        <Typography component="p">10.1. It is absolutely prohibited to use any methods, individuals, devices, mechanisms, or agreements to commit fraud, violate any applicable laws, interfere with other partners&apos; activities, or falsify data related to referrals via the Links. This includes, but is not limited to, employing spyware, automated tools to increase link clicks or fill in required information, programs that steal information, cookie-stuffing, and other forms of click fraud or deceptive activities.</Typography>
        <Typography component="p">10.2. Maverex Services Kft strictly prohibits the use of proxies, VPNs, or any other inappropriate tools and methods by you or any third party connected to you. The company reserves the right to refuse and withhold payment for all leads generated through such forbidden means.</Typography>
        <Typography component="p">10.3. Decisions about fraudulent activities are made solely at Maverex Services Kft&apos;s discretion. Partners must not generate leads or clicks under false pretenses or through fraudulent traffic. The company may refuse to pay for leads obtained through a proxy server, VPN, or other inappropriate tools detected by the Internal Fraud Protection System. Any actual or attempted fraud is grounds for immediate termination of your account and withholding of any outstanding payments.</Typography>
        <Typography component="p">10.4. You acknowledge that engaging in any of the following actions, either directly or indirectly, will result in the termination of all your accounts without payment, including any accrued commissions, and will result in a permanent ban from participating in any of our Programs:</Typography>
        <List>
          <ListItem>
            Sending unsolicited mass emails, chat messages, instant messages, ICQ or IRC messages,
            newsgroup posts, or any other form of SPAM.
          </ListItem>
          <ListItem>
            Promoting or dealing with content involving rape, bestiality, violence, child pornography,
            or any other illegal activity. This includes using forbidden content in hidden text,
            links, meta tags, graphics, or any HTML.
          </ListItem>
          <ListItem>
            Misleading or attempting to deceive or defraud the company in any way.
          </ListItem>
          <ListItem>
            Using deceptive domain names that redirect traffic and trick individuals into
            viewing obscene material or minors into viewing harmful content.
          </ListItem>
          <ListItem>
            Providing false or incomplete account information and hijacking traffic.
          </ListItem>
          <ListItem>
            Requiring hits or subscriptions to access services or goods on your website or a third-party website.
          </ListItem>
          <ListItem>
            Using any dishonest mechanism to gain hits or subscriptions, including partner subscriptions.
            Test registrations must be requested via email as provided in your account documentation.
          </ListItem>
          <ListItem>
            Violating any rights of any legal entity or individual, including trademark, patent, copyright,
            privacy, publicity, or other intellectual, personal, or property rights.
          </ListItem>
          <ListItem>
            Engaging in deceitful practices in relation to any other sponsor or partner program.
          </ListItem>
          <ListItem>
            Hosting on free, non-adult hosting services where adult content is prohibited,
            such as Angelfire, GeoCities, Xoom, Tripod, CJB.net, or Hypermart.
          </ListItem>
          <ListItem>
            Providing false information about Program Websites, including misrepresenting membership fees,
            terms of membership, or content.
          </ListItem>
          <ListItem>
            Misusing the terms of Maverex Services Kft and Program Websites, such as advising
            members to cancel and re-register.
          </ListItem>
          <ListItem>
            Using any form of misleading or dishonest advertising.
          </ListItem>
        </List>
        <Typography component="p">10.5. The use or acquisition of online promotion tools or schemes that confuse or mislead due to their similarity to any of the Partners&apos; service marks, trademarks, or URLs is prohibited. This includes AdWords, pay-per-click models, search engine keywords, sponsored links, or similar advertising schemes.</Typography>

        <Typography component="h6" variant="h5">11. Representations and Warranties</Typography>
        <Typography component="p">11.1. You confirm that you have the authority to enter into this Agreement. This Agreement establishes your valid, legal, and binding obligation enforceable against you in accordance with its terms. In line with the other provisions of this Agreement, Maverex Services Kft ensures that it will not intentionally violate any rules, laws, or regulations applicable to Maverex Services Kft’s business operations or the products provided by Maverex Services Kft.</Typography>

        <Typography component="h6" variant="h5">12. Modifications</Typography>
        <Typography component="p">12.1. We reserve the right to amend any provisions of this Agreement at any time, in addition to any notifications allowed under this Agreement. You will be informed of such changes either by a notification posted on the Site or by an email inviting you to review the revised Agreement. The amended Agreement will become effective and apply to you once it is posted on the Site. Your continued use of our Service following such a posting will be regarded as your acceptance of the amended Agreement.</Typography>
        <Typography component="p">12.2. It is your responsibility to check the Agreement periodically and familiarize yourself with any changes. Amendments may include changes to payment operations and limitations on your account usage. If you cannot accept the changes, you have the right to terminate this Agreement without penalty within 7 (seven) business days of the changes being posted.</Typography>
        <Typography component="p">12.3. Your continued participation in the Partner Program after a modification notice has been posted on the Site will be considered your acceptance of the amendment. Additionally, Maverex Services Kft may discontinue, change, or suspend any part or feature of a Link or an offer, and/or modify, alter, remove, or change any text, tags, graphics, or banner advertisements associated with a Link. You agree to promptly comply with any request from Maverex Services Kft to remove, change, or alter any graphic, banner advertisement, or Link used by you under the Partner Program.</Typography>

        <Typography component="h6" variant="h5">13. Unprejudiced Investigation</Typography>
        <Typography component="p">13.1. By confirming this Agreement, you acknowledge that you have read and agreed to all its terms. You have independently evaluated the suitability of your participation in the Partner Program and each offer managed by Maverex Services Kft, and you are not relying on any representations, warranties, or proposals other than those explicitly stated in this Agreement or the Partner Program.</Typography>

        <Typography component="h6" variant="h5">14. Indemnification</Typography>
        <Typography component="p">14.1. You agree to indemnify, defend, and hold harmless the owners/operators of Maverex Services Kft and the Program Websites, along with their affiliates, subsidiaries, directors, partners, licensors, employees, officers, owners, and agents, from any claims, demands, actions, losses, liabilities, judgments, damages, costs, settlements, and expenses (including attorney fees and costs) arising from:</Typography>
        <List className="listNumber">
          <ListItem>
            Any breach or violation of this Agreement, including any warranties,
            representations, agreements, covenants, or obligations assumed by you herein;
          </ListItem>
          <ListItem>
            Any misuse by you or a party under your reasonable control, or gaining access through you,
            of Links or the intellectual property of Maverex Services Kft or its Partners;
          </ListItem>
          <ListItem>
            Any claims related to your Media, including but not limited to the information
            contained on such Media (excluding links).
          </ListItem>
        </List>

        <Typography component="h6" variant="h5">15. Limitation of Liability</Typography>
        <Typography component="p">15.1. The Company shall not be held accountable for any issues beyond our reasonable control, including but not limited to, program websites or links being inaccessible or unusable, computer errors, technical failures, damage, loss, disruption, or distortion of information.</Typography>
        <Typography component="p">15.2. We will not be liable for any incidental, consequential, indirect, personal injury/wrongful death, exemplary, or special damages, including but not limited to business damages or loss of profits, even if such damages are foreseeable and we have been advised of the possibility of such damages.</Typography>
        <Typography component="p">15.3. Our total liability to you for any claims and all bases of liability will be limited to the total commission amounts we have paid you in the seven months immediately preceding such a claim.</Typography>

        <Typography component="h6" variant="h5">16. Disclaimers</Typography>
        <Typography component="p">16.1. Services, links, Partner programs, and products provided by Maverex Services Kft in connection with them are provided to Partners on an &quot;as is&quot; basis.</Typography>
        <Typography component="p">16.2. Except as explicitly stated in this document, Maverex Services Kft denies all warranties, whether implied, express, or statutory, including, but not limited to, implied warranties of merchantability, non-infringement, and fitness for a particular purpose, as well as any warranties arising from business practices, usage, or trade.</Typography>
        <Typography component="p">16.3. Maverex Services Kft does not guarantee that the links or Partner Program will meet all the specific requirements of the Partner or that the Partner Program or links will be completely error-free or uninterrupted. Maverex Services Kft explicitly denies any responsibility for any actions or missteps of a client, its services, or products. Maverex Services Kft does not ensure that a Partner will earn a specific amount of income.</Typography>

        <Typography component="h6" variant="h5">17. Miscellaneous and Governing Law</Typography>
        <Typography component="p">17.2. This Agreement represents the entire understanding between you and Maverex Services Kft regarding its subject matter and supersedes all prior agreements, understandings, or covenants, whether written or oral. You agree that Maverex Services Kft will not follow or adhere to any online terms or Partner insertion orders that modify, contradict, or add to this Agreement, even if Maverex Services Kft “clicks” or otherwise indicates its acceptance.</Typography>
        <Typography component="p">17.1. You are responsible for covering all expenses, fees, and costs incurred by attorneys and/or Maverex Services Kft to enforce compliance with the terms of this Agreement.</Typography>
        <Typography component="p">17.3. You may not assign this Agreement or any part of it without prior written consent from Maverex Services Kft. Maverex Services Kft may assign this Agreement at any time by notifying you. This Agreement will bind and benefit the successors, legal representatives, and authorized assigns of the parties.</Typography>
        <Typography component="p">17.4. The following clauses will remain in effect upon termination of this Agreement: </Typography>
        <List>
          <ListItem>
            Confidentiality
          </ListItem>
          <ListItem>
            Intellectual Property Rights and License
          </ListItem>
          <ListItem>
            Termination
          </ListItem>
          <ListItem>
            Anti-Spam Policy
          </ListItem>
          <ListItem>
            Scams and Fraud
          </ListItem>
          <ListItem>
            Representations and Warranties, and
          </ListItem>
          <ListItem>
            Unprejudiced Investigation - Disclaimers.
          </ListItem>
        </List>
        <Typography component="p">If any provision of this Agreement is found to be invalid, void, or inoperative, the remaining provisions will continue in effect, and the invalid part will be modified to the minimum extent necessary to reflect the original intentions of the parties.</Typography>
        <Typography component="p">17.5. The parties to this Agreement act as independent contractors with respect to each other in all matters arising in connection with this Agreement. Nothing in this Agreement shall be construed as creating a joint venture, partnership, employment, association, or agency relationship between the parties. No business relationship or impediment in the performance of any rights under this Agreement constitutes a waiver of such rights. No waiver of any non-performance or violation shall be considered an ongoing waiver or a waiver of any other violation or non-performance. By submitting an application to the Partner Program, you confirm that you have read this Agreement in full and agree to abide by all its terms.</Typography>

        <Typography component="p">17.6. If you do not wish to be bound by the terms of this Agreement, you should not apply for participation in the Partner Program. If an individual accesses this Agreement on behalf of a legal entity, that individual represents that they have the authority to bind the legal entity to this Agreement.</Typography>
        <Typography component="p">
          17.7. This Agreement is governed by the laws of Hungary. Any claim or dispute arising out of or
          in connection with the formation or subject matter of this Agreement (including non-contractual
          claims or disputes) will be governed and interpreted according to the laws of Hungary.
          The courts of Hungary have exclusive jurisdiction to hear, determine, and review any dispute,
          lawsuit, claim, or action that may arise under or outside of this Agreement. The parties consent
          to the personal jurisdiction and chosen venue for resolving disputes between them.
          For any questions or clarifications regarding the provisions listed above,
          please contact us via email at&nbsp;
          <a href="mailto:info@maverexserv.com">info@maverexserv.com</a>
        </Typography>
      </Box>
    </Container>
  );
};

export default Terms;
