// @flow
import React from "react";
import {
  ComposedChart, CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis, Tooltip, Area,
} from "recharts";
import withStyles from "@mui/styles/withStyles";
import type { ItemData } from "../../services/dashboardApi";

export const formatDate = (v: string): string => v;
export const formatNumber = (v: number): string => (v >= 1000 ? `${v / 1000}k` : String(v));

type Props = {
  data: Array<ItemData>,
}

const getFallbackData: () => ItemData[] = () => [
  { amount: 0, date: "" },
  { amount: 0, date: "" },
];

const GlobalCss = withStyles({
  "@global": {
    "& .recharts-default-legend": {
      display: "flex",
      justifyContent: "flex-end",
      flexGap: "20px",
      gap: "20px",
      margin: 0,
    },
    "& .recharts-legend-item": {
      margin: "0!important",
    },
  },
})(() => null);

const Chart = ({
  data,
}: Props) => {
  const commonAreaProps = {
    type: "monotone",
    strokeWidth: 2,
    strokeLinecap: "round",
    activeDot: true,
    isAnimationActive: false,
  };

  const dot1 = {
    dot: {
      r: 4,
      fill: "url(#stroke1)",
      strokeWidth: 3,
      stroke: "white",
      fillOpacity: 1,
    },
  };
  const dot2 = {
    dot: {
      r: 4,
      fill: "url(#stroke2)",
      strokeWidth: 3,
      stroke: "white",
      fillOpacity: 1,
    },
  };

  return (
    <ResponsiveContainer width="100%" height="100%" minHeight={350} minWidth={300}>
      <ComposedChart
        data={data.length ? data : getFallbackData()}
        margin={{
          top: 30, right: 0, left: 0, bottom: 0,
        }}
      >
        <CartesianGrid />
        <Tooltip labelFormatter={formatDate} />
        <XAxis dataKey="date" tickFormatter={formatDate} tick={{ fontSize: 12, color: "rgba(0,0,0,0.5" }} />
        <YAxis tickFormatter={formatNumber} tick={{ fontSize: 12, color: "rgba(0,0,0,0.5" }} />
        <Legend
          wrapperStyle={{ top: 5, left: 0 }}
          verticalAlign="top"
          align="right"
        />

        <defs>
          <linearGradient id="stroke1">
            <stop stopColor="#3CEBC1" />
          </linearGradient>
          <linearGradient id="stroke2">
            <stop stopColor="#B45AD4" />
          </linearGradient>
          <linearGradient id="fill1" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#3CEBC1" stopOpacity={0.2} />
            <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.1} />
          </linearGradient>
          <linearGradient id="fill2" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#B45AD4" stopOpacity={0.2} />
            <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.1} />
          </linearGradient>
        </defs>

        <Area {...commonAreaProps} {...dot1} dataKey="amount" name="Commission" stroke="url(#stroke1)" fill="url(#fill1)" />
        <Area {...commonAreaProps} {...dot2} dataKey="quantity" name="Leads" stroke="url(#stroke2)" fill="url(#fill2)" />

      </ComposedChart>
      <GlobalCss />
    </ResponsiveContainer>
  );
};

export default Chart;
