// @flow
import React, { type Node } from "react";

const Logo: () => Node = () => (
  <svg width="144" height="40" viewBox="0 0 144 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M58.4078 11.8556V28.2927H54.4039V18.4351L50.7278 28.2927H47.4966L43.7971 18.4117V28.2927H39.7932V11.8556H44.523L49.1357 23.2351L53.7015 11.8556H58.4078Z" fill="white" />
    <path d="M60.491 21.7366C60.491 20.3942 60.7408 19.2156 61.2403 18.201C61.7554 17.1864 62.45 16.4059 63.3242 15.8595C64.1983 15.3132 65.1739 15.04 66.251 15.04C67.172 15.04 67.9759 15.2273 68.6627 15.602C69.3652 15.9766 69.9037 16.4683 70.2783 17.0771V15.2273H74.2822V28.2927H70.2783V26.4429C69.8881 27.0517 69.3417 27.5434 68.6393 27.9181C67.9525 28.2927 67.1486 28.48 66.2276 28.48C65.1661 28.48 64.1983 28.2068 63.3242 27.6605C62.45 27.0985 61.7554 26.3103 61.2403 25.2956C60.7408 24.2654 60.491 23.079 60.491 21.7366ZM70.2783 21.76C70.2783 20.761 69.9974 19.9727 69.4354 19.3951C68.8891 18.8176 68.2178 18.5288 67.4217 18.5288C66.6257 18.5288 65.9466 18.8176 65.3847 19.3951C64.8383 19.9571 64.5652 20.7376 64.5652 21.7366C64.5652 22.7356 64.8383 23.5317 65.3847 24.1249C65.9466 24.7024 66.6257 24.9912 67.4217 24.9912C68.2178 24.9912 68.8891 24.7024 69.4354 24.1249C69.9974 23.5473 70.2783 22.759 70.2783 21.76Z" fill="white" />
    <path d="M83.056 24.5464L85.8891 15.2273H90.1506L85.5145 28.2927H80.574L75.9379 15.2273H80.2228L83.056 24.5464Z" fill="white" />
    <path d="M104.152 21.5493C104.152 21.9239 104.129 22.3142 104.082 22.72H95.0203C95.0827 23.5317 95.3403 24.1561 95.793 24.5932C96.2613 25.0146 96.831 25.2254 97.5022 25.2254C98.5013 25.2254 99.1959 24.8039 99.5861 23.961H103.848C103.629 24.8195 103.231 25.5922 102.653 26.279C102.091 26.9659 101.381 27.5044 100.523 27.8946C99.6642 28.2849 98.7042 28.48 97.6427 28.48C96.3627 28.48 95.2232 28.2068 94.2242 27.6605C93.2252 27.1142 92.4447 26.3337 91.8827 25.319C91.3208 24.3044 91.0398 23.1181 91.0398 21.76C91.0398 20.402 91.313 19.2156 91.8593 18.201C92.4213 17.1864 93.2017 16.4059 94.2008 15.8595C95.1998 15.3132 96.3471 15.04 97.6427 15.04C98.9071 15.04 100.031 15.3054 101.014 15.8361C101.998 16.3668 102.763 17.1239 103.309 18.1073C103.871 19.0907 104.152 20.2381 104.152 21.5493ZM100.054 20.4956C100.054 19.8088 99.8203 19.2624 99.352 18.8566C98.8837 18.4507 98.2983 18.2478 97.5959 18.2478C96.9247 18.2478 96.3549 18.4429 95.8866 18.8332C95.4339 19.2234 95.153 19.7776 95.0437 20.4956H100.054Z" fill="white" />
    <path d="M110.268 17.4049C110.736 16.6868 111.322 16.1249 112.024 15.719C112.727 15.2976 113.507 15.0868 114.366 15.0868V19.3249H113.265C112.266 19.3249 111.517 19.5434 111.017 19.9805C110.518 20.402 110.268 21.1512 110.268 22.2283V28.2927H106.264V15.2273H110.268V17.4049Z" fill="white" />
    <path d="M128.596 21.5493C128.596 21.9239 128.572 22.3142 128.525 22.72H119.464C119.526 23.5317 119.784 24.1561 120.237 24.5932C120.705 25.0146 121.275 25.2254 121.946 25.2254C122.945 25.2254 123.639 24.8039 124.03 23.961H128.291C128.073 24.8195 127.675 25.5922 127.097 26.279C126.535 26.9659 125.825 27.5044 124.966 27.8946C124.108 28.2849 123.148 28.48 122.086 28.48C120.806 28.48 119.667 28.2068 118.668 27.6605C117.669 27.1142 116.888 26.3337 116.326 25.319C115.764 24.3044 115.483 23.1181 115.483 21.76C115.483 20.402 115.757 19.2156 116.303 18.201C116.865 17.1864 117.645 16.4059 118.644 15.8595C119.643 15.3132 120.791 15.04 122.086 15.04C123.351 15.04 124.475 15.3054 125.458 15.8361C126.441 16.3668 127.206 17.1239 127.753 18.1073C128.315 19.0907 128.596 20.2381 128.596 21.5493ZM124.498 20.4956C124.498 19.8088 124.264 19.2624 123.796 18.8566C123.327 18.4507 122.742 18.2478 122.039 18.2478C121.368 18.2478 120.799 18.4429 120.33 18.8332C119.878 19.2234 119.597 19.7776 119.487 20.4956H124.498Z" fill="white" />
    <path d="M138.435 28.2927L135.929 24.6634L133.822 28.2927H129.49L133.799 21.6195L129.373 15.2273H133.869L136.374 18.8332L138.482 15.2273H142.813L138.435 21.8068L142.93 28.2927H138.435Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M2.73175 0C1.38471 0 0.292725 1.09199 0.292725 2.43902C0.292725 3.78606 1.38471 4.87805 2.73175 4.87805H7.12199C8.19962 4.87805 9.07321 5.75164 9.07321 6.82927C9.07321 7.9069 8.19962 8.78049 7.12199 8.78049H6.14638C4.79935 8.78049 3.70736 9.87248 3.70736 11.2195C3.70736 12.5665 4.79935 13.6585 6.14638 13.6585H14.9269C16.0045 13.6585 16.8781 14.5321 16.8781 15.6098C16.8781 16.6874 16.0045 17.561 14.9269 17.561H10.0488C8.70179 17.561 7.6098 18.653 7.6098 20C7.6098 21.347 8.70179 22.439 10.0488 22.439H14.9269C16.0045 22.439 16.8781 23.3126 16.8781 24.3902C16.8781 25.4679 16.0045 26.3415 14.9269 26.3415H6.14638C4.79935 26.3415 3.70736 27.4335 3.70736 28.7805C3.70736 30.1275 4.79935 31.2195 6.14638 31.2195H7.12199C8.19962 31.2195 9.07321 32.0931 9.07321 33.1707C9.07321 34.2484 8.19962 35.122 7.12199 35.122H2.73175C1.38471 35.122 0.292725 36.2139 0.292725 37.561C0.292725 38.908 1.38471 40 2.73175 40H14.4391C15.7861 40 16.8781 38.908 16.8781 37.561C16.8781 36.2139 15.7861 35.122 14.4391 35.122H13.9513C12.8736 35.122 12 34.2484 12 33.1707C12 32.0931 12.8736 31.2195 13.9513 31.2195H22.2439C23.591 31.2195 24.683 30.1275 24.683 28.7805C24.683 27.4335 23.591 26.3415 22.2439 26.3415H21.7561C20.6785 26.3415 19.8049 25.4679 19.8049 24.3902C19.8049 23.3126 20.6785 22.439 21.7561 22.439H31.0244C32.3715 22.439 33.4635 21.347 33.4635 20C33.4635 18.653 32.3715 17.561 31.0244 17.561H21.7561C20.6785 17.561 19.8049 16.6874 19.8049 15.6098C19.8049 14.5321 20.6785 13.6585 21.7561 13.6585H22.2439C23.591 13.6585 24.683 12.5665 24.683 11.2195C24.683 9.87248 23.591 8.78049 22.2439 8.78049H13.9513C12.8736 8.78049 12 7.9069 12 6.82927C12 5.75164 12.8736 4.87805 13.9513 4.87805H14.4391C15.7861 4.87805 16.8781 3.78606 16.8781 2.43902C16.8781 1.09199 15.7861 0 14.4391 0H2.73175Z" fill="url(#paint0_linear_55_1438)" />
    <defs>
      <linearGradient id="paint0_linear_55_1438" x1="0.292725" y1="23.4146" x2="33.4635" y2="23.4146" gradientUnits="userSpaceOnUse">
        <stop stopColor="#2BF3FE" />
        <stop offset="1" stopColor="#1064AA" />
      </linearGradient>
    </defs>
  </svg>
);

export default Logo;
